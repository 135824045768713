@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply inline-flex text-primary-600 bg-white items-center px-4 py-2 border border-primary-600 text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed;
  }
  .btn-primary {
    @apply text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-500;
  }
  .btn-danger {
    @apply text-white bg-red-600 border-red-600 hover:bg-red-700 focus:ring-red-500;
  }
}

.print-color-exact {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  print-color-adjust: exact !important; /*Firefox*/
}
